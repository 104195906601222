import React from "react";
import UnitWrapper from "../components/unit-wrapper";

function Observation({ location }) {
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  return <UnitWrapper id={id} location={location} />;
}

export default Observation;
