import React, { useContext } from "react";
import PropTypes from "prop-types";
import ObservationDetails from "./data/observation-details";
import ObservationLayout from "./data/observation-layout";
import useLocale from "../hooks/useLocale";
import useDataApi from "../hooks/useDataApi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const config = require(`../../config/i18n`);
// This will be used in next few weeks to make a generalised observation Details for all sites, override it for now
function UnitWrapper({ id, location }) {
  const { locale } = useLocale();
  const { apiAuthHeader, apiUrl } = config;
  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${apiUrl}/yemen/${locale}/media/${id}`,
    [],
    apiAuthHeader
  );
  // get observation details and pass on to the details component
  return (
    <>
      {isLoading ? (
        <div
          css={{
            margin: "5rem 10rem",
          }}
        >
          <Skeleton count={10} />
        </div>
      ) : (
        <ObservationLayout location={location} isModal={true}>
          <ObservationDetails observation={data} locale={locale} />
        </ObservationLayout>
      )}
    </>
  );
}

UnitWrapper.propTypes = {
  id: PropTypes.object,
  location: PropTypes.object,
};

export default UnitWrapper;
